import { Line, mixins } from 'vue-chartjs'

export default {
  extends: Line,
  gradient1: null,
  gradient2: null,
  mounted() {
    this.gradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)

    this.gradient.addColorStop(0, 'rgba(23,200,165, .75)')
    this.gradient.addColorStop(0.35, 'rgba(23,200,165, .35)');
    this.gradient.addColorStop(.85, 'rgba(23,200,165, 0)');

    this.gradient2 = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)

    this.gradient2.addColorStop(0, 'rgba(0,95,254, 1.0)')
    this.gradient2.addColorStop(0.35, 'rgba(0,95,254, .5)');
    this.gradient2.addColorStop(.85, 'rgba(0,95,254, 0)');

    this.renderChart({
      labels: ['Q1-2019', 'Q2-2019', 'Q3-2019', 'Q4-2019', 'Q1-2020'],
      datasets: [
        {
          label: 'Est. Value',
          backgroundColor: this.gradient,
          data: [10, 40, 39, 62, 70]
        },
        {
          label: 'Cost Basis',
          backgroundColor: this.gradient2,
          // borderColor: '#005ffe',
          data: [8, 38, 35, 40, 42]
        }
      ]
      // #005ffe - rgb(0,95,254), #00145a - rgb(0,20,90), #17c8a5 - rgb(23,200,165)
    }, { responsive: true, maintainAspectRatio: false })
  }

}
