<template>
  <v-card class="pt-3 px-5 my-3 dark--gradient" min-width="250px">
    <div class="overline mb-0 white--text">Investment Goals</div>
    <v-col v-if="!netWorth || !portfolioAlloc || !annualDeals || !totalDeals">
      <v-alert
        type="warning"
      >Complete the "My Strategy" page to display your annual investment goals.</v-alert>
    </v-col>
    <v-row v-else>
      <v-col align="center" class="px-1">
        <span class="white--text"># per Year</span>
        <v-col class="px-0">
          <v-progress-circular
            :rotate="270"
            :size="100"
            :width="15"
            :value="annualCount/annualDeals*100"
            color="white"
            class="white--text"
          >{{ annualCount }} / {{ annualDeals }}</v-progress-circular>
        </v-col>
        <v-col class="py-0">
          <span class="font-weight-light mr-1 white--text">$</span>
          <span
            class="font-weight-light white--text"
            v-text="(avgCheckSize*annualDeals/1000).toFixed(1)"
          ></span>
          <span class="font-weight-light mr-1 white--text">k / year</span>
        </v-col>
      </v-col>
      <v-col align="center" class="px-1">
        <span class="white--text">Total Startups</span>
        <v-col class="px-0">
          <v-progress-circular
            :rotate="270"
            :size="100"
            :width="15"
            :value="totalCount/totalDeals*100"
            color="white"
            class="white--text"
          >{{ totalCount }} / {{ totalDeals }}</v-progress-circular>
        </v-col>
        <v-col class="py-0">
          <span class="font-weight-light mr-1 white--text">$</span>
          <span
            class="font-weight-light white--text"
            v-text="(avgCheckSize*totalDeals/1000).toFixed(1)"
          ></span>
          <span class="font-weight-light mr-1 white--text">k total</span>
        </v-col>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { formatMixin } from "../components/mixins/formatMixin.js";

export default {
  name: "InvestmentGoals",
  props: ["darkTheme"],
  mixins: [formatMixin],
  computed: {
    barColor() {
      if (this.darkTheme) {
        return "white";
      } else {
        return "tertiary";
      }
    },
    loadedInvestments() {
      return this.$store.getters.loadedInvestments;
    },
    netWorth() {
      return this.$store.getters.netWorth;
    },
    portfolioAlloc() {
      return this.$store.getters.portfolioAlloc;
    },
    annualDeals() {
      return this.$store.getters.annualDeals;
    },
    totalDeals() {
      return this.$store.getters.totalDeals;
    },
    annualCount() {
      let mstoYear = 365 * 24 * 60 * 60 * 1000;
      let currentDate = new Date();

      let annualInvestments = this.loadedInvestments.filter(
        investment =>
          currentDate.getTime() - new Date(investment.date).getTime() < mstoYear
      );

      return annualInvestments.length;
    },
    totalCount() {
      return this.loadedInvestments.length;
    },
    avgCheckSize() {
      return (this.netWorth * this.portfolioAlloc) / 100 / this.totalDeals;
    }
  }
};
</script>