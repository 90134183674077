<template>
  <div class="dashboard">
    <v-container v-if="isLoading">
      <v-progress-circular indeterminate class="primary--text" :width="7" :size="70" />
    </v-container>
    <!-- TODO: turn these into expansion boxes for more details. Edit on separate page -->
    <v-container v-else style="max-width:1200px">
      <v-row>
        <v-col xs="12" sm="6" md="6" lg="3" class="mr-0">
          <v-card min-width="250px" max-height="250px" class="pb-1 mt-3">
            <v-tabs :grow="true">
              <v-tab>Value</v-tab>
              <v-tab>Cost Basis</v-tab>
              <v-tab-item>
                <p
                  class="text-center justify-center display-1 mt-2 mb-0"
                >$ {{ numberWithCommas(accountValue) }}</p>
              </v-tab-item>
              <v-tab-item>
                <p
                  class="text-center justify-center display-1 mt-2 mb-0"
                >$ {{ numberWithCommas(costBasis) }}</p>
              </v-tab-item>
            </v-tabs>
            <v-tabs :grow="true">
              <v-tab>Est. IRR</v-tab>
              <v-tab>Est. ROI</v-tab>
              <v-tab-item>
                <p
                  class="text-center justify-center display-1 mt-2 mb-0"
                >IRR: {{ overallIRR.toFixed(1) }}%</p>
              </v-tab-item>
              <v-tab-item>
                <p
                  class="text-center justify-center display-1 mt-2 mb-0"
                >ROI: {{ overallROI.toFixed(1) }}x</p>
              </v-tab-item>
            </v-tabs>
          </v-card>
          <InvestmentGoals />
        </v-col>
        <!-- TODO: Piechart example wont stay within correct column setting responsive-->
        <v-col xs="12" sm="6" md="6" lg="4" xl="4">
          <v-card class="py-3 px-0 my-3">
            <div class="overline px-5">Startup Holdings</div>
            <v-tabs :grow="true" :show-arrows="false">
              <v-tab>Industry</v-tab>
              <v-tab>Value</v-tab>
              <v-tab>Security</v-tab>
              <v-tab-item>
                <PieChart :chartData="industryData" :options="industryOptions" />
              </v-tab-item>
              <v-tab-item>
                <PieChart :chartData="valueData" :options="valueOptions" />
              </v-tab-item>
              <v-tab-item>
                <PieChart :chartData="securityData" :options="securityOptions" />
              </v-tab-item>
            </v-tabs>
          </v-card>
        </v-col>

        <v-col xs="12" sm="12" md="12" lg="5" xl="5">
          <v-card class="py-3 px-5 my-3">
            <div class="overline mb-3">
              Performance
              <v-tooltip bottom max-width="300px">
                <template v-slot:activator="{ on }">
                  <v-icon color="primary" dark v-on="on">help_outline</v-icon>
                </template>
                <span>The valuation over time chart will be included in a future update.</span>
              </v-tooltip>
            </div>
            <v-tabs>
              <v-tab>(Coming Soon)</v-tab>
              <v-tab>Exits</v-tab>
              <v-tab>Limits</v-tab>

              <v-tab-item>
                <div>
                  <LineChart style="max-height: 350px" />
                </div>
              </v-tab-item>
              <v-tab-item>
                <v-data-table
                  :headers="exitCompanyHeaders"
                  :items="exitCompanyValues"
                  class="elevation-1"
                  hide-default-footer
                  :disable-sort="true"
                />
              </v-tab-item>

              <v-tab-item>
                <InvestmentLimits :dark="false" />
              </v-tab-item>
            </v-tabs>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <InvestmentList />
    <v-dialog v-model="isFirstLogin" width="500">
      <v-card>
        <v-card-title class="title grey lighten-2" primary-title>Welcome to VentureWallet!</v-card-title>
        <v-card-text>
          <strong>Beta Test Agreement:</strong> this is an early-access software version (Beta). By proceeding you agree to our
          <router-link to="/terms" target="_blank">Terms of Use</router-link>&nbsp;and
          <router-link to="/privacy" target="_blank">Privacy Policy</router-link>.
        </v-card-text>
        <v-card-text>
          <strong>Beta Test Feedback:</strong> please report any bugs, issues, feature requests, or other feedback using the chat feature in the lower right.
        </v-card-text>
        <v-card-text>
          <strong>Sample Investments:</strong> since this is your first time logging in, we have pre-loaded your Dashboard with several investments to demo some of the capabilities. When you are ready, you can delete those investments and enter your own.
        </v-card-text>
        <v-card-text>
          <strong>Suggested Device (Computer):</strong> for the best experience, we recommend using VentureWallet on your desktop or laptop computer.
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn color="primary" text @click="isFirstLogin = false">Let's Go!</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PieChart from "../components/PieChart.js"; // Piechart
import LineChart from "../components/LineChart.js";
import InvestmentList from "../components/InvestmentList.vue";
import InvestmentGoals from "../components/InvestmentGoals.vue";
import InvestmentLimits from "../components/InvestmentLimits.vue";
import { formatMixin } from "../components/mixins/formatMixin.js";
import { mapGetters } from "vuex";

export default {
  name: "Dashboard",
  components: {
    InvestmentGoals,
    InvestmentList,
    InvestmentLimits,
    PieChart,
    LineChart
  }, // Piechart
  mixins: [formatMixin],
  data: () => ({
    exitCompanyHeaders: [
      {
        text: "",
        align: "left",
        sortable: false,
        value: "name"
      },
      { text: "Cost Basis", value: "costBasis" },
      { text: "Exit Value", value: "estValue" },
      { text: "ROI", value: "ROI" }
    ]
  }),
  computed: {
    ...mapGetters(["isLoading", "error"]),
    industryData() {
      return {
        labels: this.$store.getters.industryData.labels,
        datasets: [
          {
            label: "Startups by Industry",
            backgroundColor: this.chartBackgroundColors,
            data: this.$store.getters.industryData.values
          }
        ]
      };
    },
    // All below is for PieChart
    industryCount() {
      //This updates, but the display doesnt react
      let sumValues = Object.values(
        this.$store.getters.industryData.values
      ).reduce((a, b) => a + b, 0);

      return this.numberWithCommas(sumValues);
    },
    industryOptions() {
      return {
        elements: {
          //TODO: for some reason, the other two Actives work, but this one doesnt - but sumValues is getting right value, it just doesnt react
          // center: {
          //   text: "Active: $" + this.industryCount,
          //   color: "#36A2EB", //Default black
          //   fontStyle: "Helvetica", //Default Arial
          //   sidePadding: 15 //Default 20 (as a percentage)
          // }
        }
      };
    },
    valueData() {
      return {
        labels: this.$store.getters.chartValueData.labels,
        datasets: [
          {
            label: "Startups by Value",
            backgroundColor: this.chartBackgroundColors,
            data: this.$store.getters.chartValueData.values
          }
        ]
      };
    },
    valueCount() {
      //This is reactive
      let sumValues = Object.values(
        this.$store.getters.chartValueData.values
      ).reduce((a, b) => a + b, 0);

      return this.numberWithCommas(sumValues);
    },
    valueOptions() {
      //This wasn't reactive when deleting investments - fixed by triggering isLoading in ConfirmDelete to re-render the component
      return {
        elements: {
          center: {
            text: "Active: $" + this.valueCount,
            color: "#36A2EB", //Default black
            fontStyle: "Helvetica", //Default Arial
            sidePadding: 15 //Default 20 (as a percentage)
          }
        }
      };
    },
    securityData() {
      return {
        labels: this.$store.getters.chartSecurityData.labels,
        datasets: [
          {
            label: "Startups by Security Type",
            backgroundColor: this.chartBackgroundColors,
            data: this.$store.getters.chartSecurityData.values
          }
        ]
      };
    },
    securityCount() {
      let sumValues = Object.values(
        this.$store.getters.chartSecurityData.values
      ).reduce((a, b) => a + b, 0);
      return this.numberWithCommas(sumValues);
    },
    securityOptions() {
      return {
        elements: {
          center: {
            text: "Active: $" + this.securityCount,
            color: "#36A2EB", //Default black
            fontStyle: "Helvetica", //Default Arial
            sidePadding: 15 //Default 20 (as a percentage)
          }
        }
      };
    },
    investments() {
      return this.$store.getters.loadedInvestments;
    },
    accountValue() {
      let sumValues = this.investments.reduce(function(prev, cur) {
        if (!cur.isExited && !cur.isFailed) {
          return +prev + +cur.estValue;
        } else return prev;
      }, 0);

      return sumValues;
    },
    costBasis() {
      let sumValues = this.investments.reduce(function(prev, cur) {
        if (!cur.isExited && !cur.isFailed) {
          return +prev + +cur.amount;
        } else return prev;
      }, 0);

      return sumValues;
    },
    overallIRR() {
      let today = new Date();
      let mstoYear = 365 * 24 * 60 * 60 * 1000;
      let portfolioValue = this.costBasis;

      // Calculate a simple weighted IRR by summing all the IRRs for each investment and scaling based on the original investment size
      let weightedIRR = this.investments.reduce(function(prev, cur) {
        let purchaseDay = new Date(cur.date);
        let thisROI = cur.estValue / cur.amount;
        let numYears = (today.getTime() - purchaseDay.getTime()) / mstoYear;
        let expIRR = 1 / numYears;
        let thisIRR = Math.pow(thisROI, expIRR) - 1;
        let thisWeighted = thisIRR * (cur.amount / portfolioValue);

        if (numYears < 1) {
          //Only calculate IRR for investments over 1 year old - otherwise can get bogus numbers
          return +prev;
        } else {
          return +prev + +thisWeighted * 100;
        }
      }, 0);

      if (isNaN(weightedIRR)) {
        return 0;
      } else if (weightedIRR > 999) {
        return 999.9; //For bogus IRR, return max
      } else {
        return weightedIRR;
      }
    },
    overallROI() {
      return this.accountValue / this.costBasis;
    },
    exitCompanyValues() {
      let exitCompanies = [];

      this.investments.forEach(function(company) {
        //TODO: numberWithCommas formatting doesn't work
        // let thisCost = this.numberWithCommas(company.amount);
        // let thisValue = this.numberWithCommas(company.estValue);

        //TODO: Doesn't re-sort top when adding or updating values - Vue reactive?
        if (company.isExited) {
          exitCompanies.push({
            name: company.company_name,
            costBasis: "$" + company.amount,
            estValue: "$" + company.estValue,
            ROI: (company.estValue / company.amount).toFixed(1) + "x"
          });
        }
      });

      exitCompanies.sort((a, b) => (+a.ROI < +b.ROI ? 1 : -1));
      exitCompanies = exitCompanies.slice(0, 6);

      return exitCompanies;
    },
    isFirstLogin: {
      get() {
        return this.$store.getters.isFirstLogin;
      },
      set(value) {
        this.$store.dispatch("setFirstLogin");
      }
    }
  }
};
</script>
